import axios from 'axios';

const axiosInstance = axios.create({baseURL: 'https://node.mybeatcoach.com/api/', responseType: 'json'});

/**
 * Iniciar sesion
 * */
export const login = (email, pass) => {
    const headers = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    return new Promise((resolve, reject) => {
        axiosInstance.post('login', {email: email, password: pass}, {headers}).then(res => {
            console.log('THEN LOGIN', res)
            console.log(res.data.user.token)
            localStorage.setItem('token', res.data.user.token);
            localStorage.setItem('USER', JSON.stringify(res.data.user));
            resolve(res);
        }).catch(err => {
            console.log('CATCH LOGIN')
            reject(err);
        })
    })
}

/**
 * Cerrar sesion
 * */
export const logout = async () => {
    console.log("logout")
    console.log(localStorage.getItem('token'))
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('logout', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge el perfil del usuario
 * */
export const getProfile = () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('my_profile', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los paises
 * */
export const getCountries = () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get('countries',{headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge un usuario
 * */
export const getUser = (idUser) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`users/${idUser}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Añade una especialidad a un coach
 * */
export const addSpeciality = (idUser, idSpeciality) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        speciality_id: idSpeciality
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('add_user_speciality', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Elimina una especialidad de un coach
 * */
export const deleteSpeciality = (idUser, idSpeciality) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        speciality_id: idSpeciality
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('delete_user_speciality', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Añade un departamento a un usuario
 * */
export const addDepartment = (idUser, idDepartment) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        department_id: idDepartment
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('add_user_department', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Elimina una especialidad de un usuario
 * */
export const deleteDepartment = (idUser, idDepartment) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        department_id: idDepartment
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('delete_user_department', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Elimina una organizacion de un usuario
 * */
export const deleteOrganization = (idUser, idOrganization) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        organization_id: idOrganization
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('delete_user_organization', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * Envia una push notification a todos los usuarios
 */
export const sendPushNotification = (title, description) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        title: title,
        description: description
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('send_push_notification', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
