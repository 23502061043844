import axios from 'axios';

const axiosInstance = axios.create({baseURL: 'https://node.mybeatcoach.com/api/', responseType: 'json'});

/**
 * Recoge un coach
 * */
export const getCoach = (idCoach) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`get_coach/${idCoach}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los baneos
 * */
export const getBans = () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`get_user_bans`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Elimina un baneo
 * */

export const deleteBan = (idBan) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        id: idBan
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post(`delete_user_bann`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * Elimina un coach
 */
export const deleteCoach = (idUser) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    const data = {
        user_id: idUser
    }
    return new Promise((resolve, reject) => {
        axiosInstance.post(`soft_delete_user`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
