import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { Space, Table, message } from 'antd';
import { getHolidays, deleteHolidays, createHoliday } from '../../services/holidays.service';
import AddButton from '../../components/addButton/addButton.component';
import Button from '../../components/button/button.component';
import InputDateText from '../../components/inputDateText/inputDateText';
import * as moment from 'moment';
import './holidays.css';

const Holidays = () => {
    const navigate = useNavigate();
    const [holidays, setHolidays] = useState([]);
    const [newHoliday, setNewHoliday] = useState(null);

    useEffect(() => {
        getAllHolidays();
    }, [])

    const getAllHolidays = () => {
        getHolidays().then(res => {
            console.log('Vacaciones recogidas con exito', res.data);
            setHolidays(res.data);
        }).catch(err => {
            console.log('Error al recoger las vacaciones', err);
        })
    }

    const removeHoliday = (id) => {
        deleteHolidays(id).then(res => {
            console.log('Festivo eliminado con exito', res.data);
            getAllHolidays();
        }).catch(err => {
            console.log('Error al eliminar el festivo', err);
        })
    }

    const createNewHoliday = () => {
        //console.log('newHoliday', newHoliday);
        //console.log('newHoliday fromat', moment(newHoliday).format('yyyy/MM/DD'));
        if (newHoliday !== null) {
            createHoliday(newHoliday).then(res => {
                console.log('Día de vacaciones creado con exito')
                //message.success('Se ha creado el nuevo día festivo.');
                getAllHolidays();
            }).catch(err => {
                console.log('Error al crear el dia de vacaciones', err);
                message.error('No se ha podido crear el nuevo día festivo.')
            })
        } else {
            message.error('Añade una fecha para crear el nuevo día festivo.')
        }

    }


    const columns = [
        {
          title: 'Fecha',
          dataIndex: 'date', // an
          key: 'date',
          width: '90%',
          render: (text, record) =>
                <div className={'containerButtonDeleteBan'}>
                    <span>{moment(record.date).format('DD/MM/yyyy')}</span>
                </div>
        },
        {
            title: ' ',
            dataIndex: 'delete',
            key: 'delete',
            width: '10%',
            render: (text, record) =>
                <div className={'containerButtonDeleteBan'}>
                    <button className={'buttonDeleteBan'} onClick={() => removeHoliday(record.id)}>
                        Eliminar
                    </button>
                </div>
        },
    ];

    return (
        <div className='viewHolidaysContainer'>
            <div className='holidaysAddButton'>
                <InputDateText oneData={true} changeValue={value => setNewHoliday(value)}/>
                <div style={{width: '45%'}}>
                    <Button text={'Añadir día festivo'} press={() => createNewHoliday()}/>
                </div>
            </div>
            <div className={'holidaysTableStyle'}>
                <Table bordered className="custom-table"  columns={columns} dataSource={holidays} scroll={false}/>
            </div>
        </div>
    )
}

export default Holidays;