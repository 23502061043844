import React from 'react';
import './avatar.css';
import { ImagesUrl } from '../../utils/ImageAssets';
import NotUser from '../../assets/img/notUser.png';


const AddAvatar = ({ src, onClick }) => (
    <img
    className='avatarStyle'
      src={src}
      alt="Avatar"
      onClick={onClick}
    />
  );


export default AddAvatar;
