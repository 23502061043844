import React from 'react';
import {useNavigate} from 'react-router-dom';

import './notFound.css';
import {ImageAssets} from '../../utils/ImageAssets';

// Vista error 404 cuando no existe la ruta
const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={'containerNotFound'}>
            <img src={ImageAssets.notFound}/>

            <div className={'containerContentNotFound'}>
                <p className={'titleNotFound'}>Página no encontrada</p>
                <p className={'subtitleNotFound'}>Lo sentimos, no se ha encontrado la página que buscas</p>

                <button className={'buttonNotFound'} onClick={() => navigate('/')}>Volver al Inicio</button>
            </div>
        </div>
    )
}

export default NotFound;
