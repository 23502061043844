import axios from 'axios';

const axiosInstance = axios.create({baseURL: 'https://railsmbc.mybeatcoach.com/stats/'});


/**
 * Recoge las estadisticas generales
 * */
export const getGeneralStats = async (startDate, endDate, format = "") => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    let response_type;
    if (format != "") {
        response_type = 'arraybuffer';
    } else {
        response_type = 'json';
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`general${format}?startDate=${startDate}&endDate=${endDate}`,{responseType: response_type}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}




/**
 * Recoge las estadisticas generales
 * */
export const getCoachStats = async (startDate, endDate, id, format = "") => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }


    let response_type;
    if (format != "") {
        response_type = 'arraybuffer';
    } else {
        response_type = 'json';
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`coach_services${format}?startDate=${startDate}&endDate=${endDate}&coach_id=${id}`,{responseType: response_type}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * Recoge las estadisticas de una empresa
 * */
export const getCompanyStats = async (startDate, endDate, organizationId, gender, age, departmentId, format = "") => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    let response_type;
    if (format != "") {
        response_type = 'arraybuffer';
    } else {
        response_type = 'json';
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`usage${format}?startDate=${startDate}&endDate=${endDate}&organization_id=${organizationId}&gender=${gender}&age=${age}&department_id=${departmentId}`,{responseType: response_type}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
