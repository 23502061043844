import React, { useState, useEffect } from 'react';
import { CoachService, OrganizationService } from '../../services/mbc.service';

// Muestra el componente de dropdawn de las empresas
const CompaniesDropdown = ({value, initialText, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(initialText);
  const [companies, setCompanies] = useState([]);

  // Maneja el componente cuando cambias la opcion
  const handleOptionChange = (event) => {
    if (event.target.value == null || event.target.value == '') {
      console.log('this is an event', event.target.value)
      setSelectedOption(0);
      onChange({id: 0})
    } else {
      const idSeleccionado = parseInt(event.target.value, 10);
      const opcionEncontrada = companies.find((opcion) => opcion.id === idSeleccionado);
      setSelectedOption(opcionEncontrada.id);
      onChange(opcionEncontrada)
    }
    
    
  };

  // Se ejecuta al renderizar el componente
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        // LLama al servidor para recoger todas la empresas
        const data = await OrganizationService.getAll()
        setCompanies(data);
      } catch (error) {
        console.error('Error al obtener los coaches:', error);
      }
    };
    fetchCompanies();
  }, []);

  return (
    <div >
      <select className='containerStyle' id="dropdown-select" onChange={handleOptionChange}>
        {!value && <option value="">{initialText}</option>}
        {companies.map((option) => (
          <option key={option.id} value={option.id} selected={option.id === value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CompaniesDropdown;
