import React, { useState } from 'react';

// Muestar el componented e dropdown de los departamentos
const DepartmentDropdown = ({ value, options, initialText, onChange, disabled }) => {
  const [selectedOption, setSelectedOption] = useState(initialText);

  // Maneja el cambio de opcion en el componente
  const handleOptionChange = (event) => {
    if (event.target.value !== false && event.target.value !== 'false') {
      const idSeleccionado = parseInt(event.target.value, 10);
      const opcionEncontrada = options.find((opcion) => opcion.id === idSeleccionado);
      setSelectedOption(opcionEncontrada.id);
      // Envia a la vista el objeto seleccionado
      onChange(opcionEncontrada)
    }
  };

  return (
    <div >
      <select className='containerStyle' id="dropdown-select" onChange={handleOptionChange} disabled={disabled}>
        {(!value || options.length === 1) && <option value={false}>Departamento</option>}
        {options.map((option) => (
          <option key={option.id} value={option.id} selected={value === option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DepartmentDropdown;
