import React, {useEffect, useState, useRef} from 'react';
import './addNotification.css'


import InputText from '../../../components/inputText/inputText.component';
import Button from '../../../components/button/button.component';
import TopNavBar from '../../../components/topNavBar/topNavBar.component';
import { SpecialtiesService } from '../../../services/mbc.service';
import Modal from '../../../components/modal/modalComponent';

import { sendPushNotification } from '../../../services/user.service';
import {message} from 'antd';

const AddNotification = () => {
    const initialSpecialtyState = {
        title: '',
        description: '',
      }
      const [formData, setFormData] = useState(initialSpecialtyState);
      const formRef = useRef(); // Crear referencia al formulario
      const [errors, setErrors] = useState({});
      const [showModal, setShowModal] = useState(false);
    
      // Maneja los valor de los input al cambiar y los guarda para poder crear la especialidad
    const handleInputChange = (event) => {
    if (event.target.value.length <= 500) {
        setFormData({ ...formData, description: event.target.value });
        formData.description = event.target.value
    }
    };
    
    const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    };
    
    // Cierra la modal
    const closeModal = () => {
    setShowModal(false);
    };
    
    // Valida los campos del furmulario
    const validate = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = 'El titulo es obligatorio';
    if (!formData.description) newErrors.description = 'La descripcíon obligatoria';
    return newErrors;
    };
    
    // En el caso de no tener error los campos del formulario
    // Crea la especialidad
    const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
        console.log('Procesar el envío del formulario:', formData);
        try {
          if (window.confirm('¿Estás seguro de enviar esta notificación?')) {
            sendPushNotification(formData.title, formData.description).then(res => {
              message.success('Notificación enviado correctamente.')
              setShowModal(true);
              resetForm()
            }).catch(err => {
              message.error('No se ha podido enviar la notificación, intentalo de nuevo.')
            })
          }
          
        } catch (error) {
        console.error(error);
        }
    } else {
        setErrors(validationErrors);
    }
    };
    
    // Resetea los valores del formulario
    const resetForm = () => {
    formRef.current.reset();
    setFormData(initialSpecialtyState)
    }

    return (
    <div className='generalContainer'>
      <TopNavBar text="Agregar notificación"></TopNavBar>
      <form ref={formRef} onSubmit={handleSubmit}>

        <div className='form-containerSpecialty'> 
          <div>
              <div className='form-field'>
                <InputText placeholder="Titulo" 
                  name="title"
                  value={formData.title} changeValue={handleChange}> 
                </InputText>    
              </div>

            <div>
                <textarea className="descripcion-coach"
                    id="description"
                    name="description"
                    maxLength={500}
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Escribe la descripción de la notificación"
                    rows={5}
                    cols={50}
                />
              <div>
                <small>{formData.description.length}/500</small>
            </div>
        </div>
            </div>
            </div>  

            <div className='center'>
            <Button text="Guardar" type="submit" ></Button>  
            </div>
      </form> 
      <div>
        <Modal
          showModal={showModal}
          closeModal={closeModal}
          title="Correcto!"
          description="Se ha creado la notificación correctamente."
        />
      </div>
    </div>
    )
}

export default AddNotification