import React, { useState , useEffect,useRef} from 'react';
import AddAvatar from '../../../components/avatar/addAvatar';
import AddButton from '../../../components/addButton/addButton.component';
import './editUser.css';
import InputText from '../../../components/inputText/inputText.component';
import Button from '../../../components/button/button.component';
import CountryDropdown from '../../../components/countryDropdown/countryDropdown.component';
import GeneralDropdown from '../../../components/generalDropdown/generalDropdown.component';
import InputDateText from '../../../components/inputDateText/inputDateText';
import TopNavBar from '../../../components/topNavBar/topNavBar.component';
import CompaniesDropdown from '../../../components/companiesDropdown/companiesDropdown';
import { OrganizationService, UserService, ImageService } from '../../../services/mbc.service';
import DepartmentDropdown from '../../../components/departmentDropdown/departmentDropdown';
import Modal from '../../../components/modal/modalComponent';
import { useLocation } from 'react-router-dom';
import { ImagesUrl } from '../../../utils/ImageAssets';
import moment from 'moment';
import {getUser} from "../../../services/user.service";
import NotUser from '../../../assets/img/notUser.png';


const EditUser = () => {
  const location = useLocation();
  const user = location.state.idUser;

  console.log('USER EDIT', user)

  /*const initialState = {
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      password: user.password,
      image: user.image,
      birthday: user.birthday,
      gender: user.gender,
      country_id: user.country_id,
      city: user.city,
      organizations: user.Organizations[0],
      departments: user.Departments[0],
      user_type: user.user_type
  }*/
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [image, setImage] = useState('');
  const [birthday, setBirthday] = useState(null);
  const [gender, setGender] = useState('');
  const [countryId, setCountryId] = useState('');
  const [city, setCity] = useState('');
  const [organizationId, setOrganizationId] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [userType, setUserType] = useState('');
  const [cities, setCities] = useState([]);
  const [employeId, setEmployeId] = useState('');


  const [file, setFile] = useState(null);
  //const [initialData, setinitialData] = useState(initialState);
  const [formData, setFormData] = useState({});
  const [company, setCompany] = useState({});
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef();
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(null);

  useEffect(() => {
      console.log('ID user in useeffect', location.state.idUser)
      getUser(location.state.idUser).then(res => {
          setName(res.data.name)
          setLastname(res.data.lastname);
          setEmail(res.data.email);
          console.log('this is an image', typeof res.data.image)
          setImage(res.data.image);
          setPreview(ImagesUrl.base_url_img + res.data.image)
          setBirthday(res.data.birthday);
          setGender(res.data.gender);
          setCountryId(res.data.country_id);
          setCity(res.data.city);
          setOrganizations(res.data.Organizations.length > 0 ? res.data.Organizations : []);
          setOrganizationId(res.data.Organizations.length > 0 ? res.data.Organizations[0].id : null)
          setDepartments(res.data.Departments.length > 0 ? res.data.Departments : []);
          setDepartmentId(res.data.Departments.length > 0 ? res.data.Departments[0].id : null)
          setUserType(res.data.user_type);
          console.log('res.data', res.data)
          setEmployeId(res.data.employe_id)
          
          if (res.data.Organizations.length > 0) {
              fetchDepartments(res.data.Organizations[0].id)
          }
          if (res.data.country_id !== null && res.data.country_id !== undefined && res.data.country_id !== '') {
              getCityOptions(res.data.country_id)
          }
          console.log('Usuario recogido', res.data)
      }).catch(err => {
          console.log('Error al recoger el usuario', err);
      })
  }, [])

  /*useEffect(() => {
    if(file == null){
      if(user.image != null){
      setPreview(ImagesUrl.base_url_img + user.image)
    }
  }
}, []);*/



const handleCompanyChange = (val) => {
    console.log('selecttttt', val)
    setOrganizationId(val.id)
    setFormData({ ...formData, organization_id: val.id });
    fetchDepartments(val.id)
}

const generes = [
    { id: 1, name: 'Hombre' },
    { id: 2, name: 'Mujer' },
    { id: 3, name: 'Otro' },
  ];

  const userTypes = [
    { id: 1, name: 'user' },
    { id: 2, name: 'admin' },
  ];



  const closeModal = () => {
    setShowModal(false);
  };

  const fetchDepartments = async (companyId) => {
    console.log("fetchDepartments")
    try {
        const companyResp = await OrganizationService.getById(companyId)
        console.log(companyResp)
        setCompany(companyResp)
        setDepartments(companyResp.Departments)
      } catch (error) {
        console.error('Error al obtener los coaches:', error);
      }
  };

  const handleFileChange = (e) => {
    const fileTarget = e.target.files[0];
    console.log('fileTarget', fileTarget)
     setFile(fileTarget)
    if (fileTarget) {
      console.log('enter with file target')
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('new preview', reader.result)
        setPreview(reader.result);
      };
      reader.readAsDataURL(fileTarget);
    } else {
      setPreview(null);
    }
  };

  const handleAvatarClick = () => {
    document.getElementById('photoInput').click();
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log('the image', organizationId)
    e.preventDefault();
      let dataUpdate;
      if (password !== '' && password2 !== '' && password === password2) {
          dataUpdate = {
              name: name,
              lastname: lastname,
              email: email,
              password: password,
              image: image,
              birthday: birthday,
              gender: gender,
              country_id: countryId,
              city: city,
              organizations: organizationId,
              departments: departmentId,
              user_type: userType,
              employe_id: employeId
          }
      } else {
          dataUpdate = {
              name: name,
              lastname: lastname,
              email: email,
              image: image,
              birthday: birthday,
              gender: gender,
              country_id: countryId,
              city: city,
              organizations: organizationId,
              departments: departmentId,
              user_type: userType,
              employe_id: employeId
          }
      }
    try {
    const response = await UserService.update(location.state.idUser,dataUpdate);
        if (password !== '' && password2 !== '' && password === password2) {
            setPassword('');
            setPassword2('');
        }
      await uploadImage()
      setShowModal(true);
    } catch (error) {
      console.error(error);
      alert('Error al actualizar');
    }
};

  const uploadImage = async () => {
    if(file != null){
    try {
      const imageData = new FormData();
      imageData.append('file',file);
      imageData.append('user_id',location.state.idUser)
      console.log(imageData)
      const response = await ImageService.upload(imageData);
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      throw error;
    }
  }
  };

    const getCityOptions = (isoCountry) => {
        let headers = new Headers();
        headers.append("X-CSCAPI-KEY", "aUF2QTVOTDZxbG16Zjc4TFZEcTQxa3ZSWTc4YkJjYjlJeFZPdHNPdQ==\n");

        let requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        fetch(`https://api.countrystatecity.in/v1/countries/${isoCountry}/cities`, requestOptions)
            .then(response => response.text())
            .then(result => setCities(JSON.parse(result)))
            .catch(error => console.log(error));
    }

  return (
    <div className='generalContainer'>
      <TopNavBar text="Editar usuario"></TopNavBar>
      <form  ref={formRef}  onSubmit={handleSubmit}>
      <div className='form-container'>
      <div className='formAddPhoto'>
           <form>
            <div  className='avatarStyle' >
                <input
                type="file"
                id="photoInput"
                name="image"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                />
                <AddAvatar src={image || file ? preview : NotUser} onClick={handleAvatarClick} />
            </div>
            </form>
            <div className='addStyle'>
            <AddButton text="Agregar foto" press={handleAvatarClick}  id="photoInput"></AddButton>
            </div>
        </div>

      <div >
        <div className='form-field'>
        <InputText placeholder={'Nombre'}
        name="name"
        oneData={true}
        value={name} changeValue={value => setName(value)}> </InputText>
        {errors.name && <p>{errors.name}</p>}
       </div>

       <div className='form-field'>
        <InputText placeholder={'Apellidos'}
        name="lastname"
        oneData={true}
        value={lastname} changeValue={value => setLastname(value)}> </InputText>
        {errors.lastname && <p>{errors.lastname}</p>}
       </div>
        <div className='form-field'>
        <InputText
        name="email"
        oneData={true}
        placeholder={'Correo'}  type="email" changeValue={value => setEmail(value)} value={email}> </InputText>
        {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-field'>
        <InputText
        name="employe_id"
        oneData={true}
        placeholder="Id empleado" changeValue={value => setEmployeId(value)} value={employeId}> </InputText>
        {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-field'>
        <InputText placeholder="*********"
         name="password"
        oneData={true}
        type="password" changeValue={value => setPassword(value)} value={password}> </InputText>
        {errors.password && <p>{errors.password}</p>}
         </div>
         <div className='form-field'>
        <InputText placeholder="*********"
         name="password2"
         oneData={true}
         type="password" changeValue={value => setPassword2(value)} value={password2}> </InputText>
        {errors.password && <p>{errors.password}</p>}
         </div>
         <div className='form-field'>
         <GeneralDropdown options={userTypes} initialText={userType} onChange={(val)=>{
          setUserType(val)
        }}>
         </GeneralDropdown>
         </div>
         </div>
        </div>

<div className='containerDropdowns'>
        <div className='form-fields-dropdowns'>
         <div className='form-field-dropdown'>
                 <InputDateText
                     placeholder="Fecha de nacimiento"
                     oneData={true}
                     name="birthday"
                     value={moment(birthday).format('yyyy-MM-DD')}
                     changeValue={value => setBirthday(value)}
                 >
                 </InputDateText>

         </div>
         <div className='form-field-dropdown'>
        <CountryDropdown
            value={countryId}
            initialText={countryId ? countryId : 'Pais'}
            onChange={(val)=>{
                console.log("country change")
                setCountryId(val);
                getCityOptions(val)
            }}
        >
        </CountryDropdown>
         </div>
         <div className='form-field-dropdown'>
         <CompaniesDropdown
             value={organizationId}
             initialText={organizationId ? organizationId : 'Empresa'}
             onChange={handleCompanyChange}
         >
         </CompaniesDropdown>
         </div>
         </div>

         <div className='form-fields-dropdowns'>
         <div className='form-field-dropdown'>
        <GeneralDropdown
            value={gender}
            options={generes}
            initialText={gender}
            onChange={(val)=>{
                setGender(val);
            }}
        >
        </GeneralDropdown>
         </div>
         <div className='form-field-dropdown'>
         <GeneralDropdown
             value={city}
             options={cities}
             initialText={city ? city : 'Ciudad'}
             onChange={(val)=>{
                 setCity(val)
                formData.city = val;
            }}
         >
         </GeneralDropdown>
         </div>
         <div className='form-field-dropdown'>
         <DepartmentDropdown
             value={departmentId}
             options={departments}
             initialText={departmentId ? departmentId : 'Departamento'}
             onChange={(val)=>{
                setDepartmentId(val.id);
             }}
             //disabled={!initialState.organizations}
         >
         </DepartmentDropdown>
         </div>
         </div>
         </div>
         <div className='center'>
        <Button text="Guardar" type="submit" ></Button>
        </div>

    </form>
<div>
    <Modal
        showModal={showModal}
        closeModal={closeModal}
        title="Correcto!"
        description="Se ha modificado el usuario correctamente."
      />
</div>
    </div>
);};

export default EditUser;
