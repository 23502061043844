import React, {useEffect, useState} from 'react';
import './bans.css';
import Searcher from "../../components/searcher/searcher.component";
import {Table} from 'antd';
import {getBans, deleteBan} from '../../services/coach.service';
import {ImagesUrl} from "../../utils/ImageAssets";

// Vista de conversaciones bloqueadas
const Bans = () => {
    const [bans, setBans] = useState([])
    const [allBans, setAllBans] = useState([])
    // Inicializa la constante con las columnas que va a haber en la tabla
    const columns = [
        {
            title: 'Empresa',
            dataIndex: 'organization',
            key: 'organization',
        },
        {
            title: 'Coach',
            dataIndex: 'coach',
            key: 'coach',
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Razon',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: ' ',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) =>
                <div className={'containerButtonDeleteBan'}>
                    <button className={'buttonDeleteBan'} onClick={() => removeBan(record.id)}>
                        Eliminar
                    </button>
                </div>
        },
    ];

    // Se ejecuta al etrar en la vista
    useEffect(() => {
        showBans();
    }, [])

    // Recoge todos los usuarios bloqueados
    const showBans = () => {
        getBans().then(res => {
            setBans(res.data)
            setAllBans(res.data)
            console.log('Baneos recogidos con exito', res.data);
        }).catch(err => {
            console.log('Error al recoger los baneos', err);
        })
    }

    // Elimina usuarios bloqueados
    const removeBan = (idBan) => {
        if (window.confirm('¿Deseas eliminar el baneo?')) {
            deleteBan(idBan).then(res => {
                showBans();
                console.log('Baneo eliminado', res.data);
            }).catch(err => {
                console.log('Error al eliminar el baneo', err)
            })
        }
    }

    
    const searchCompany = (inputValue) => {
        let bansSearched = [];
        if (inputValue !== '' && inputValue !== null) {
            allBans.map(ban => {
                let banName = ban.name.toUpperCase();
                if (banName.includes(inputValue.toUpperCase())) {
                    bansSearched.push(ban);
                }
            })
            setBans(bansSearched);
        } else {
            setBans(allBans);
        }
    }

    return (
        <div className='viewBansContainer'>
            <div className={'searchBarStyle'}>
                <Searcher changeValue={value => searchCompany(value)}/>
            </div>
            <div className={'bansTableStyle'}>
                <Table className="custom-table"  columns={columns} dataSource={bans} scroll={false} />
            </div>
        </div>
    )
}

export default Bans;
