import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import './articleComponent.css';
import {IconAssets} from '../../utils/ImageAssets';

import DotQuantity from '../dotQuantity/dotQuantity.component';
import moment from 'moment';
import AddButton from "../addButton/addButton.component";
import 'font-awesome/css/font-awesome.min.css';

// Muestra el componte de categoria de articulos con articulos
const ArticleComponent = ({section, press, idPostCategoryEdit, idPostCategoryRemove, idPost}) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);


    return (
        <div className={'containerCompleteArticleComponent'}>
            <div className={'containerArticleComponent'} onClick={() => setOpen(!open)}>

                <div className={'containerDetailsArticleComponent'}>
                    <div className={'detailsArticleComponent'}>
                        <p className={'titleArticleComponent'}>{section.name}</p>
                        <p className={'descriptionArticleComponent'}>{section.description}</p>
                    </div>

                    <div className={'detailsDateArticleComponent'}>
                        <p className={'dateArticleComponent'}>{moment(section.createdAt).format('DD/MM/yyyy')}</p>
                        {/*<DotQuantity />*/}
                        {/* Muestra los iconos para editar y eliminar el clicarlos se envia el id a la vista para hacer la accion necesaria */}
                        <div className={'containerIconsArtcileComponent'}>
                            <i style={{fontSize: 20}} className="fa fa-pencil" onClick={event => idPostCategoryEdit({id: section.id})}></i>
                            <i style={{fontSize: 20, color: 'red'}} className="fa fa-trash"  onClick={event => idPostCategoryRemove({event: event, id: section.id})}></i>
                        </div>
                        {/*<button id={'deleteCategory'} className={'buttonDeleteCategoryArticle'} onClick={event => idPostCategory({event: event, id: section.id})}>
                            Eliminar
                        </button>*/}
                    </div>

                    <img className={'iconArticleComponent'} src={open ? IconAssets.up : IconAssets.down}/>
                </div>
            </div>
            {open &&
                <div className={'containerSubarticlesArticlesComponent'}>

                    <div className={'subarticleArticleComponent'} onClick={() => navigate('/addArticle', {state: {id: section.id}})}>
                        <div className='addButtonArticleComponent'>
                            <img className={'iconMenuItemArtcileComponent'} src={IconAssets.add}/>
                            <p className={'textItemArticleComponent'}>{'Agregar Artículo'}</p>
                        </div>
                    </div>

                    {/* Recorre los post para mostrarlos al abrir la categoria del post */}
                    {section.Posts.map((subarticle, index) => {
                        console.log('section.Posts', section.Posts)
                        return (
                            <div className={index === section.Posts.length -1 ? 'lastSubarticleArticleComponent' : 'subarticleArticleComponent'}>
                                {/* Al clicarlo navega a la pagina del articulo */}
                                <div className={'subarticleTextArticleComponent'} onClick={() => navigate('/article', {state: {subarticle: subarticle}})}>
                                    <p className={'titleSubarticleArticleComponent'}>{subarticle.title}</p>
                                    <p className={'subtitleSubarticleArticleComponent'}>{subarticle.description}</p>
                                </div>

                                {/* Al clicarlo envia a la vista el id del post para eliminar dicho post */}
                                <button className={'buttonDeleteArticle'} onClick={() => idPost(subarticle.id)}>
                                    Eliminar
                                </button>
                            </div>
                        )
                    })
                    }
                </div>
            }
        </div>

    )
}

export default ArticleComponent;
