import React from 'react';

import './avatar.css';
import { ImageAssets } from '../../utils/ImageAssets';
import NotUser from '../../assets/img/notUser.png'

const Avatar = ({image}) => {

    return (
        <div className='containerAvatar'>
            <img className={'imageNavBar'} src={image ? `https://node.mybeatcoach.com/${image}` : NotUser}/>

            <div className='dotAvatarConnected'></div>
        </div>
    )
}

export default Avatar;
