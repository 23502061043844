import React, {useEffect, useState} from 'react';
import {message, Spin} from 'antd';

import './companiesStats.css';

import InputDateText from '../../components/inputDateText/inputDateText';
import Button from '../../components/button/button.component';
import CardStats from '../../components/cardStats/cardStats.component';
import ContainerGraphics from "../../components/containerGraphics/containerGraphics.component";
import CompaniesDropdown from "../../components/companiesDropdown/companiesDropdown";
import GeneralDropdown from "../../components/generalDropdown/generalDropdown.component";
import DepartmentDropdown from "../../components/departmentDropdown/departmentDropdown";

import {getCompanyStats} from '../../services/stats.service';
import {IconAssets} from "../../utils/ImageAssets";
import {OrganizationService} from "../../services/mbc.service";

const CompaniesStats = () => {
    const [stats, setStats] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [organizationId, setOrganizationId] = useState("");
    const [age, setAge] = useState('');
    const [sex, setSex] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [departments, setDepartments] = useState([]);
    const [userType, setUserType] = useState(null);

    // Inicializa la constante con los valores del select de sexo
    const sexList = [
        {id: 0, name: 'Hombre'},
        {id: 1, name: 'Mujer'},
        {id: 2, name: 'Otro'},
    ];

    // Inicializa la constante con los valores del select de edad
    const agesList = [
        {id: 0, name: '18-24'},
        {id: 1, name: '25-34'},
        {id: 2, name: '35-44'},
        {id: 3, name: '45-54'},
        {id: 4, name: '55-64'},
        {id: 5, name: '65+'},
    ]

    // Se ejecuta al entrar en la vista
    useEffect(() => {
        // Recoge el usuario de localstorage
        let user = localStorage.getItem('USER');
        // Se guarda el tipo de usuario
        setUserType(JSON.parse(user).user_type)
        // Si el tipo de usuario es admin
        if (JSON.parse(user).user_type === 'admin') {
            // Al llamar al server para recoger los valor de las estadisticas por defecto ya le envia la empresa a la que pertenece
            setOrganizationId(JSON.parse(user).Organizations[0].id);
            fetchDepartments(JSON.parse(user).Organizations[0].id);
            getStats('', '', JSON.parse(user).Organizations[0].id, '', '', '');
        } else {
            // Si no es admin lo manda todo vacio para recoger todas las estadisticas por defecto contando con todas las empresas
            getStats('', '', '', '', '', '');
        }
    }, [])

    // Se ejecuta al entrar en la vista y trae las estadisticas filtradas o sin filtrar segun el tipo de usuario
    useEffect(() => {
        getStats('', '', userType !== 'admin' ? '' : organizationId, '', '', '');
    }, [])

    // LLama al servidor para recoger las estadisticas segun los parametros que le lleguen
    const getStats = (start, end, organizationId, gender, age, departmentId) => {
        getCompanyStats(start, end, organizationId, gender, age, departmentId).then(res => {
            console.log('Estadisticas recogidas con exito', res.data);
            setStats(res.data);
        }).catch(err => {
            console.log('ERROR al recoger las estadisticas', err);
        })
    }

    // Descarga un documento con los valores de las estadisticas segun los parametros enviados
    const downloadExcel = () => {
        getCompanyStats(startDate, endDate, organizationId, sex, age, departmentId,".xlsx").then(res => {
            
            console.log('Estadisticas excel', res.data);

            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              
              const url = window.URL.createObjectURL(blob);
              
              // Create an anchor element to trigger the download
              const downloadLink = document.createElement('a');
              downloadLink.href = url;
              downloadLink.download = 'estadisticas_company_mbc.xlsx'; // Specify the desired filename
              downloadLink.click();
              
              // Don't forget to clean up the URL after the download
              window.URL.revokeObjectURL(url);

        }).catch(err => {
            console.log('ERROR al recoger las estadisticas generales', err);
        })
    }

    // Aplica los filtros y vuelve a traer las estadisticas con los filtros 
    const applyFilters = () => {
        if ((startDate !== null && endDate !== null) || organizationId !== null || sex !== null || age !== null) {
            getStats(startDate, endDate, organizationId, sex, age, departmentId);
        } else {
            message.error('Rellena todos los filtros.');
        }
    }

    // Recoge todos los departamentos de una empresa por id de empresa
    const fetchDepartments = async (companyId) => {
        try {
            const company = await OrganizationService.getById(companyId)
            console.log('THIS IS A ORGANIZATION', company)
            setDepartments(company.Departments)
        } catch (error) {
            console.error('Error al obtener los coaches:', error);
        }
    };

    return (
        <div className={'containerCompaniesStats'}>
            <p className={'titleGeneralStats'}>Uso de servicio</p>

            <div className={'containerFiltersCompaniesStats'}>
                <div style={{display: 'flex', flexDirection:'column', width: '70%'}}>
                    <div className={userType !== 'admin' ? 'containerDateFiltersCompaniesStats' : 'containerDateFiltersCompaniesStatsAdmin'}>
                        <InputDateText changeValue={value => setStartDate(value)} oneData/>
                        <InputDateText changeValue={value => setEndDate(value)} oneData/>
                        {userType !== 'admin' &&
                            <CompaniesDropdown initialText={'Empresas'} onChange={value => {
                                console.log('value', value)
                                setOrganizationId(value.id)
                                fetchDepartments(value.id)
                            }}/>
                        }
                        <Button style={{width: '20%'}} text={'Aplicar'} press={() => applyFilters()}/>
                    </div>
                    <div style={{marginTop: 20}} className={'containerDateFiltersCompaniesStats'}>
                        <GeneralDropdown initialText={'Sexo'} options={sexList} onChange={value => setSex(value)}/>
                        <GeneralDropdown initialText={'Edad'} options={agesList} onChange={value => setAge(value)}/>
                        <div style={{width: '17%'}}></div>
                        {/* <DepartmentDropdown options={departments} initialText={'Departamentos'} onChange={value => setDepartmentId(value.id)} disabled={organizationId === null && userType === 'superadmin'} /> */}
                        <Button style={{width: '20%'}} text={'Descargar datos'} press={() => downloadExcel()}/>
                    </div>

                </div>

            </div>

            {stats ?
            <>
                <div className={'containerCardsCompaniesStats'}>
                    <CardStats value={stats.total_employees} text={'Total Empleados'} icon={IconAssets.groupStats}/>
                    <CardStats value={stats.total_logins} text={'Total Logins'} icon={IconAssets.lockStats}/>
                    {userType === 'admin' && <CardStats value={stats.total_sessions} text={'Total Usuarios con una interacción'} icon={IconAssets.profileStats}/>}
                    <CardStats value={stats.total_mau} text={'Usuarios con una interacción'} icon={IconAssets.responseStats}/>
                    {userType !== 'admin' && <CardStats value={stats.total_sessions} text={'Total Sesiones'} icon={IconAssets.readerStats}/>}
                    {userType === 'admin' && <CardStats value={stats.total_sessions} text={'Consultas realizadas'} icon={IconAssets.messageStats}/>}
                    <CardStats value={stats.status_rating} text={'Nivel de satisfación'} icon={IconAssets.starStats}/>

                </div>

                <ContainerGraphics title={'Tipología de sesión'} data={stats.specialities_graph} type={'donut'}/>
                {/*<ContainerGraphics title={'Gráfico comparativo Usuarios / Logins'} type={'bar'}/>*/}
            </>
                :
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 30}}>
                <Spin tip={'Cargando'} size={'large'}/>
            </div>
            }

        </div>
    )
}

export default CompaniesStats;
