import React, {useEffect, useState} from 'react';
import './rates.css'
import { Space, Table, message } from 'antd';
import moment from 'moment';

import { getRates } from '../../services/chat.service';

const Rates = () => {
    const [rates, setRates] = useState([]);

    useEffect(() => {
        getRates().then(res => {
            console.log('estas son las valoraciones', res)
            setRates(res.data.reverse())
        }).catch(err => {
            message.error('No se han podido recoger las valoraciones')
        })
    }, [])

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id', // an
          key: 'id',
          render: (text, record) => <span>{record.rate.id}</span>
        },
        {
          title: 'Fecha',
          dataIndex: 'createdAt', // an
          key: 'createdAt',
          render: (text, record) => <span>{moment(record.rate.createdAt).format('DD/MM/yyyy HH:mm')}</span>
        },
        {
          title: 'Coach valorado',
          render: (text, record) => <span>{record.coach_name}</span>
        },
        {
          title: 'Puntuación',
          dataIndex: 'rating', // an
          key: 'rating',
          render: (text, record) => <span>{record.rate.rating}</span>
        },
        {
          title: 'Valoración',
          dataIndex: 'description', // an
          key: 'description',
          render: (text, record) => <span>{record.rate.description}</span>
        }
    ];

    return (
        <div className="viewRatesContainer">
            <div className={'ratesTableStyle'}>
                <Table bordered className="custom-table" pagination={{ pageSize: 13 }}  columns={columns} dataSource={rates} scroll={false}/>
            </div>
        </div>
    )
}

export default Rates;