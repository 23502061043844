import React, { useState } from 'react';

// Muestra el componente de dropdown general (De cualquier otro valor que no se de los componentes que hacer un dropdown de unos valores especificos)
const GeneralDropdown = ({value, options, initialText, onChange, returnId }) => {
  const [selectedOption, setSelectedOption] = useState(initialText);

  // Maneja el componente cuando cambia la opcion
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // Envia a la vista la opcion seleccionada
    onChange(event.target.value)
  };

  return (
    <div >
      <select className='containerStyle' id="dropdown-select" onChange={handleOptionChange} disabled={options === null || options.length === 0}>
        {!value && <option value="">{initialText}</option>}
        {options.map((option) => (
          <option key={option.id} value={returnId ? option.id : option.name} selected={value === option.name || value === option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GeneralDropdown;
